<template>



    <div>


        <div v-if="session.user.is_guest == false">


            <v-list>

                <v-list-item>


                    <v-list-item-avatar>
                        <v-img :src="session.user.avatar"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{session.user.company}}</v-list-item-title>
                        <v-list-item-subtitle>{{session.user.first_name}} {{session.user.last_name}}</v-list-item-subtitle>

                    </v-list-item-content>


                    <v-list-item-action>
                        <v-btn text @click="logout()">Esci</v-btn>
                    </v-list-item-action>



                </v-list-item>

            </v-list>



        </div>

        <div v-else>

            <v-card>


                <v-card-text>

                    <v-text-field label="Username" @keyup.enter="login()" v-model="credentials.login" :disabled="session.logging_in"></v-text-field>
                    <v-text-field label="Password" @keyup.enter="login()" v-model="credentials.password" :disabled="session.logging_in" type="password"></v-text-field>


                    <v-btn block color="primary" depressed @click="login()" :loading="session.logging_in">Accedi</v-btn>

                    <v-alert type="error" class="mt-3" v-if="session.login_error !== null">{{session.login_error}}</v-alert>


                </v-card-text>



            </v-card>



        </div>

    </div>

</template>


<script>


    import {mapState} from "vuex";

    export default {


        name: 'login_window',
        computed: {

            ...mapState(['session'])
        },

        data: () => ({

            credentials: {login: null, password: null}

        }),

        methods: {


            login: function(){


                this.$store.dispatch('session/login',this.credentials);

            },

            logout: function(){


                this.$store.dispatch('session/logout');


            }

        }

    }


</script>