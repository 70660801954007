var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"hide-default-footer":"","items-per-page":-1,"headers":_vm.item_headers,"items":_vm.order.items},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" x ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.extras.length)?_c('div',{staticClass:"item-extras"},[_vm._v(" "+_vm._s(item.extras)+" ")]):_vm._e()]}},{key:"item.price_with_tax",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-right",attrs:{"value":item.price_with_tax,"currency":item.currency,"locale":"de","disabled":""}})]}},{key:"item.total_with_tax",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-right",attrs:{"value":item.total_with_tax,"currency":item.currency,"locale":"de","disabled":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }