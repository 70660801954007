<template>


    <div>

        <v-data-table hide-default-footer :items-per-page="-1" :headers="item_headers" :items="order.items">


            <template v-slot:item.quantity="{item}">

                {{item.quantity}} x


            </template>

            <template v-slot:item.name="{item}">

                {{item.name}}

                <div class="item-extras" v-if="item.extras.length">

                    {{item.extras}}

                </div>


            </template>

            <template v-slot:item.price_with_tax="{item}">

                <currency-input :value="item.price_with_tax" :currency="item.currency" locale="de" class="text-right" disabled/>

            </template>


            <template v-slot:item.total_with_tax="{item}">

                <currency-input :value="item.total_with_tax" :currency="item.currency" locale="de" class="text-right" disabled/>

            </template>

        </v-data-table>

    </div>

</template>

<script>


    import {mapState} from "vuex";

    export default {


        name: 'order_items_list',

        props: {
            order: {
                default: function () {
                    return false;
                },
            },
        },
        computed: {

            ...mapState(['session']),
            ...mapState(['backend']),


            item_headers: function () {


                let item_headers = [

                    {text: 'Quantity', value: 'quantity', sortable: true},
                    {text: 'Item', value: 'name', sortable: false},
                    {text: 'Price', value: 'price_with_tax', sortable: false, align:'right', cellClass:'text-right'},
                    {text: 'Total', value: 'total_with_tax', sortable: false, align:'right',  cellClass:'text-right'},

                ];

                return item_headers;

            }

        },


    }

</script>