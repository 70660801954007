<template>

    <div>

        <div v-if="session.user.is_guest == true">

          <div class="ec_page_container two_columns">

            <h1 class="ec_hmb_headline">
            Area riservata ai venditori
</h1>
            <v-btn id="ec_whitetext_btn" depressed color="primary" :to="{name: 'login'}">
              Accedi
            </v-btn>
          </div>
        </div>

      <div v-else>

            <backend_header></backend_header>
            <order-screen></order-screen>

      </div>

    </div>

</template>


<script>



    import backend_header from '../../components_backend/shops/backend_header'
    import orderScreen from "../../components_backend/screen/orderScreen";

    import {mapState} from "vuex";



    export default {
        computed: {

            ...mapState(['session'])
        },


        components: {


            backend_header,
            orderScreen

        }


    }

</script>

<style>

#ec_whitetext_btn {
  color: white !important;
}

</style>