<template>


    <div>

        <v-data-table
                show-expand
                :headers="headers"
                :items="backend.orders"
                :loading="backend.loading_orders"
                hide-default-footer
                :items-per-page="-1"

        >
            <!--:expanded="backend.orders"-->
          <template v-slot:top>

            <div class="ec_vendarea_header">

                <span class="ec_countdown">
                  Aggiornamento automatico tra {{auto_refresh_in}} secondi
                </span>

              <div class="ec_vendarea_buttons">
                <v-btn depressed @click="refresh_orders()">Aggiorna ora</v-btn>
                <v-btn depressed color="primary" disabled>Inserisci ordine</v-btn>
              </div>

            </div>

          </template>

          <template v-slot:item.shipping="{item}">

                 <span v-for="shipping in item.shipping" :key="shipping.id">
                     {{item.shipping_method_name}}
                 </span>

          </template>

          <template v-slot:item.status="{ item }">

            {{item.status_text}}

          </template>

          <template v-slot:item.actions="{item}">

                <div v-if="item.status == 'pending-confirmation'" class="ec_vendarea_buttons">

                    <v-btn depressed text color="#027d88" @click="confirmOrder()">Conferma</v-btn>
                    <v-btn depressed text color="red" @click="declineOrder()">Declina</v-btn>

                </div>

                <div v-else></div>

            </template>


            <template v-slot:item.desired_delivery_time_text="{item}">

                <v-chip label x-small>{{ item.desired_delivery_time_from | moment("from", "now") }}</v-chip>

            </template>

            <template v-slot:expanded-item="{ headers, item }">

                <td :colspan="headers.length">


                  <div class="ec_orderinfos ec_page_container two_columns">

                    <div>

                    <span class="ordh">
                      Ordine: {{item.order_number}}
                    </span>


                    <span>

                        Order total: <currency-input :value="item.total_with_tax" :currency="item.currency" locale="de" disabled/> <br>
                        <v-chip v-if="item.is_paid" label color="success" x-small class="ml-2">PAID</v-chip>
                        <v-chip v-else color="warning" label x-small class="ml-2">NOT PAID</v-chip>

                    </span>

                    <br>

                        <span>{{item.desired_delivery_time_from | moment("from", "now")}}</span>

                        <span>
                            {{item.desired_delivery_time_text}}
                        </span>

                    </div>

                    <div>

                      <span class="ordh">
                       {{item.shipping_method_name}}
                      </span>

                    <span>

                      Customer:<br>

                        {{item.billing_address.first_name}} {{item.billing_address.last_name}}<br>
                        {{item.billing_address.address_1}}<br>
                        {{item.billing_address.city}}<br><br>
                        Tel: {{item.billing_address.phone}}

                    </span>

                    </div>

                  </div>

                    <div class="ec_orderdetails">

                      <span class="ordh">
                        Order details
                      </span>


                        <order_item_list :order="item" class="my-2"></order_item_list>

                        <span class="ordh">
                        Total number of items in the order: {{item.item_count}}
                      </span>
                        <br>

                    </div>



                </td>


            </template>

        </v-data-table>

    </div>

</template>

<script>


    import {mapState} from "vuex";
    import order_item_list from "./order_item_list";

    export default {


        name: 'order_list',
        components: {

            order_item_list

        },
        computed: {

            ...mapState(['session']),
            ...mapState(['backend']),

            headers: function(){

                let headers = [
                    {text: 'Consegna', value:'shipping', sortable: false},
                    {text: 'Stato', value:'status', sortable: false},
                    {text: 'Appuntamento', value:'desired_delivery_time_text', sortable: true},
                    {text: 'Azioni', value:'actions', sortable: false},

                ];

                return headers;

            },

        },

        data: () => ({

            auto_refresh_in: 30,
            default_refresh_interval: 30,
            refresh_interval: null,

        }),

        methods: {

            confirmOrder(){

                alert('CONFIRM ORDER DUMMY');

            },

            declineOrder(){

                alert('DECLINE ORDER DUMMY');

            },

            tick() {


                if(this.auto_refresh_in <= 0){

                    this.auto_refresh_in = this.default_refresh_interval;

                } else {

                    this.auto_refresh_in = this.auto_refresh_in - 1;

                }

            },

            refresh_orders(){

                this.$store.dispatch('backend/loadOrders');
                this.auto_refresh_in = this.default_refresh_interval;


            }

        },


        mounted: function(){


            let self = this;

            clearInterval(self.refresh_interval);

            self.refresh_interval = setInterval(function(){self.tick()},1000);

        },

        destroyed: function(){


            let self = this;

            clearInterval(self.refresh_interval);
        },

        watch: {


            auto_refresh_in: function(new_value){


                if(new_value == 0){


                   this.refresh_orders();

                }

            }

        }

    }

</script>

<style>


.ec_vendarea_header {
  padding: 10px 25px;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  width: 100%;
  position: relative;
}

.ec_vendarea_buttons {
  display: flex;
  place-content: flex-end;
}

.ec_vendarea_buttons button {
  margin-left: 10px;
}

.ec_countdown {
  font-size: 14px;
}

.ec_orderinfos {
  padding: 25px 50px;
  background-color: #f2f2f2;
  margin-top: 20px;
}

.ec_orderinfos div span {
  display: flex;
}

.ec_orderdetails {
  padding: 25px 50px;
}

.ordh {
  font-size: 20px;
  font-weight: bold;
  color: #027d88;
}

</style>