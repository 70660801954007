var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.backend.orders,"loading":_vm.backend.loading_orders,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"ec_vendarea_header"},[_c('span',{staticClass:"ec_countdown"},[_vm._v(" Aggiornamento automatico tra "+_vm._s(_vm.auto_refresh_in)+" secondi ")]),_c('div',{staticClass:"ec_vendarea_buttons"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.refresh_orders()}}},[_vm._v("Aggiorna ora")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":""}},[_vm._v("Inserisci ordine")])],1)])]},proxy:true},{key:"item.shipping",fn:function(ref){
var item = ref.item;
return _vm._l((item.shipping),function(shipping){return _c('span',{key:shipping.id},[_vm._v(" "+_vm._s(item.shipping_method_name)+" ")])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_text)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'pending-confirmation')?_c('div',{staticClass:"ec_vendarea_buttons"},[_c('v-btn',{attrs:{"depressed":"","text":"","color":"#027d88"},on:{"click":function($event){return _vm.confirmOrder()}}},[_vm._v("Conferma")]),_c('v-btn',{attrs:{"depressed":"","text":"","color":"red"},on:{"click":function($event){return _vm.declineOrder()}}},[_vm._v("Declina")])],1):_c('div')]}},{key:"item.desired_delivery_time_text",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","x-small":""}},[_vm._v(_vm._s(_vm._f("moment")(item.desired_delivery_time_from,"from", "now")))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ec_orderinfos ec_page_container two_columns"},[_c('div',[_c('span',{staticClass:"ordh"},[_vm._v(" Ordine: "+_vm._s(item.order_number)+" ")]),_c('span',[_vm._v(" Order total: "),_c('currency-input',{attrs:{"value":item.total_with_tax,"currency":item.currency,"locale":"de","disabled":""}}),_vm._v(" "),_c('br'),(item.is_paid)?_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","color":"success","x-small":""}},[_vm._v("PAID")]):_c('v-chip',{staticClass:"ml-2",attrs:{"color":"warning","label":"","x-small":""}},[_vm._v("NOT PAID")])],1),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.desired_delivery_time_from,"from", "now")))]),_c('span',[_vm._v(" "+_vm._s(item.desired_delivery_time_text)+" ")])]),_c('div',[_c('span',{staticClass:"ordh"},[_vm._v(" "+_vm._s(item.shipping_method_name)+" ")]),_c('span',[_vm._v(" Customer:"),_c('br'),_vm._v(" "+_vm._s(item.billing_address.first_name)+" "+_vm._s(item.billing_address.last_name)),_c('br'),_vm._v(" "+_vm._s(item.billing_address.address_1)),_c('br'),_vm._v(" "+_vm._s(item.billing_address.city)),_c('br'),_c('br'),_vm._v(" Tel: "+_vm._s(item.billing_address.phone)+" ")])])]),_c('div',{staticClass:"ec_orderdetails"},[_c('span',{staticClass:"ordh"},[_vm._v(" Order details ")]),_c('order_item_list',{staticClass:"my-2",attrs:{"order":item}}),_c('span',{staticClass:"ordh"},[_vm._v(" Total number of items in the order: "+_vm._s(item.item_count)+" ")]),_c('br')],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }