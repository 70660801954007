<template>

    <div>

        <div v-if="backend.shops == false">

            Caricamento in corso
            <v-progress-linear indeterminate></v-progress-linear>

        </div>

        <div v-else>

            <v-select solo hide-details
                    :items="backend.shops"
                    item-text="name"
                    item-value="slug"
                    label="Select shop"
                    v-model="backend.selected_shop"
                    @change="selectShop($event)"
            ></v-select>

        </div>


    </div>

</template>


<script>

    import {mapState} from "vuex";

    export default {


        name: 'shop_list',
        data: () => ({





        }),


        computed: {


            ...mapState(['backend']),

        },

        mounted: function(){



            this.$store.dispatch('backend/loadShops');

        },

        methods: {


            selectShop: function(){

                localStorage.setItem('magdel:vendor:shop',this.backend.selected_shop)
                this.$router.push({name: 'vendor-screen', params: {vendor: this.backend.selected_shop}});


                this.$store.dispatch('backend/loadOrders');


            }


        }

    }

</script>