<template>

    <div class="general page_header">


      <v-app-bar class="ec_topbar_container" app flat>

          <div class="ec_topbar">

                <v-toolbar-title class="ec_topbar_element title">
                    <img class="logo" :src="require('@/assets/magentadelivery.png')"/>
                </v-toolbar-title>

            <span class="ec_reserved">AREA RISERVATA</span>

            <v-spacer></v-spacer>
            <shop_list></shop_list>

          </div>

        </v-app-bar>

    </div>

</template>

<script>


    import shop_list from './shop_list';

    export default {

        components: {


            shop_list

        },

        data: () => ({





        }),


        methods: {




        },

        computed: {



        }


    }

</script>

<style>

.ec_reserved {
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 0 5px;
  margin-left: 15px;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 12px;
}

</style>

