<template>


    <div>


        <div v-if="backend.orders === false">

            <v-progress-linear indeterminate></v-progress-linear>

        </div>


        <div v-else>

            <div class="ec_page_container">

                    <div>

                        <v-card class="mb-3">
                            <login_window></login_window>
                        </v-card>


                    </div>
                    <div>


                        <v-card>
                            <order_list></order_list>
                        </v-card>


                    </div>

                </div>


            </div>

      <div class="ec_page_container two_columns">

<v-card>
          <v-card-text>
            Current month stats
          </v-card-text>
</v-card>
        <v-card>
          <v-card-text>
            Past month stats
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-text>
            Menu edits
          </v-card-text>
        </v-card>

      </div>


        </div>



</template>


<script>


    import {mapState} from "vuex";
    import order_list from "./infra/order_list";
    import login_window from "../../components/login_window";

    export default {


        name: 'orderScreen',
        computed: {

            ...mapState(['session']),
            ...mapState(['backend'])
        },
        mounted: function(){

            this.$store.dispatch('backend/loadOrders');

        },

        components: {

            order_list,
            login_window

        }

    }

</script>

<style>


</style>